import { configApi } from '../http-client.js'

export const sendResetPasswordEmailApi = ({ username, email }) => configApi.put('/CSPortal/api/Users/SendResetPasswordEmail', {
	username,
	emailAddress: email
})

export const sendAdminPortalResetPasswordEmailApi = ({ username, email }) => configApi.put('/api/AdminPortalUsers/SendResetPasswordEmail', {
	emailAddress: email
})

export const resetCsPortalPasswordApi = ({ hash, password }) => configApi.put('/CSPortal/api/Users/ResetPassword', {
	passwordResetEmailLinkGUID: hash,
	password
})

export const resetAdminPortalPasswordApi = ({ hash, password }) => configApi.put('/api/AdminPortalUsers/ResetPassword', {
	passwordResetEmailLinkGUID: hash,
	password
})

export const checkAdminPortalPasswordResetLinkValidity = linkGuid => {
	return configApi.get(`/api/AdminPortalUsers/CheckPasswordResetLink?linkGUID=${linkGuid}`)
}

export const checkCsPortalPasswordResetLinkValidity = linkGuid => {
	return configApi.get(`CSPortal/api/Users/CheckPasswordResetLink?linkGUID=${linkGuid}`)
}
