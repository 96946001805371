<template>
	<Modal
		width="500px"
	>
		<template #modal-title>
			Password Reset Link Sent
		</template>
		<template #modal-content>
			An email containing a password reset link has been sent to the user's email address.
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('close')">
				Close
			</SecondaryActionButton>
		</template>
	</Modal>
</template>
<script>
import Modal from './modal.vue'
import SecondaryActionButton from './secondary-action-button.vue'
export default {
	components: { SecondaryActionButton, Modal }
}
</script>
