<template>
	<ValidationForm #default="{ handleSubmit, reset }">
		<UserManagementLayout>
			<template #content>
				<UserManagementUserForm
					:user.sync="user"
					:reset-validation="reset"
					:can-create-and-edit="canCreateAndEdit"
				>
					<template #title>
						{{ canCreateAndEdit ? 'Edit' : 'View' }} CS Portal User
					</template>
					<template #title-action>
						<PrimaryActionButton @click="viewAuditHistory">
							View Audit History
						</PrimaryActionButton>
					</template>
					<template
						v-if="ssoBypassAllowed && canCreateAndEdit"
						#bottom
					>
						<PrimaryActionButton @click="resetPassword">
							Reset Password
						</PrimaryActionButton>
					</template>
				</UserManagementUserForm>
				<PasswordResetModal
					v-if="showPasswordResetModal"
					@close="showPasswordResetModal = false"
				/>
				<UserManagementAuditHistoryModal
					v-if="showAuditHistoryModal"
					:audit-history="user.auditHistory"
					@close="showAuditHistoryModal = false"
				/>
			</template>
			<template #footer>
				<div class="d-flex">
					<SecondaryActionButton
						class="mr-4"
						@click="navigateToOverview"
					>
						Back to Overview
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton
						v-if="canCreateAndEdit"
						@click="handleSubmit(saveChanges)"
					>
						Save
					</PrimaryActionButton>
				</div>
			</template>
		</UserManagementLayout>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import UserManagementLayout from './user-management-layout.vue'
import UserManagementUserForm from './user-management-user-form.vue'
import PrimaryActionButton from '../primary-action-button.vue'
import { getUser, putUser } from '../../utils/api/users.js'
import SecondaryActionButton from '../secondary-action-button.vue'
import { USER_MANAGEMENT } from '../../router/route-names.js'
import { sendResetPasswordEmailApi } from '../../utils/api/forgotten-password.js'
import UserManagementAuditHistoryModal from './user-management-audit-history-modal.vue'
import { showSnackbar } from '../../state/snackbar.js'
import ValidationForm from '../validation-form.vue'
import { CAN_CREATE_AND_EDIT_CS_PORTAL_USERS } from '../../permissions/admin-portal-permissions.js'
import { CREATE_UPDATE_CSPORTAL_USERS } from '../../permissions/cs-portal-permissions.js'
import PasswordResetModal from '../password-reset-modal.vue'
export default {
	components: { PasswordResetModal, ValidationForm, UserManagementAuditHistoryModal, SecondaryActionButton, PrimaryActionButton, UserManagementUserForm, UserManagementLayout },
	props: {
		userId: Number,
		ssoEnabled: Boolean,
		ssoBypassDomains: null,
		productId: Number
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			originalEmail: null,
			user: {},
			showPasswordResetModal: false,
			showAuditHistoryModal: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'hasPermission']),
		canCreateAndEdit () {
			if (this.productId === 1) { return this.productAreaPermission(CAN_CREATE_AND_EDIT_CS_PORTAL_USERS) } else return this.hasPermission(CREATE_UPDATE_CSPORTAL_USERS)
		},
		ssoBypassAllowed () {
			if (!this.user.email) {
				return false
			}
			if (this.originalEmail.toLowerCase() !== this.user.email.toLowerCase()) {
				return false
			}
			if (this.ssoEnabled) {
				return this.ssoBypassDomains?.find(m => this.user.email.toLowerCase().endsWith(m.domain))
			}
			return true
		}
	},
	async created () {
		const { data: user } = await getUser(this.userId)
		this.user = user
		this.originalEmail = user.email
	},
	methods: {
		navigateToOverview () {
			this.$router.push({ name: USER_MANAGEMENT })
		},
		viewAuditHistory () {
			this.showAuditHistoryModal = true
		},
		async resetPassword () {
			await sendResetPasswordEmailApi({
				username: this.user.username,
				email: this.originalEmail
			})
			this.showPasswordResetModal = true
		},
		async saveChanges () {
			await putUser(this.user)
			this.showSnackbar('Your changes to this user have been saved')
			this.navigateToOverview()
		}
	}
}
</script>
