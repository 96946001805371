<template>
	<Modal full-screen>
		<template #modal-title>
			Audit History
		</template>
		<template #modal-content>
			<v-simple-table
				class="my-n4"
				dense
			>
				<template #default>
					<thead>
						<tr>
							<th
								scope="col"
								style="width: 100px;"
							>
								Audit ID
							</th>
							<th
								scope="col"
								style="width: 200px;"
							>
								Date Time
							</th>
							<th
								scope="col"
								style="width: 200px;"
							>
								Action
							</th>
							<th
								scope="col"
								style="width: calc(100% - 500px);"
							>
								Details
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="record in auditHistory"
							:key="record.auditId"
						>
							<td>
								{{ record.auditId }}
							</td>
							<td>
								{{ record.dateTime }}
							</td>
							<td>
								{{ record.action }}
							</td>
							<td>
								{{ record.details }}
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('close')">
				Close
			</SecondaryActionButton>
		</template>
	</Modal>
</template>

<script>
import SecondaryActionButton from '../secondary-action-button.vue'
import Modal from '../modal.vue'
export default {
	components: { Modal, SecondaryActionButton },
	props: {
		auditHistory: {
			type: Array,
			default: () => []
		}
	}
}
</script>
